









































































































































































































import { Component, Watch, Ref } from 'vue-property-decorator';
import WorkOrderFlowService from '@common-src/service/work-order-flow';
import FormComponent from '@common-src/mixins/form-component';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ViewModeType } from '@common-src/model/enum';
import { generateUUID } from '@common-src/utils/base_util';
import OrgService from '@common-src/service/org';
import CommonService from '@common-src/service/common';
import { TimeOptions, DocType, Dateformat, TemplateType, ChannelList, FilterFileType } from '@common-src/filter/engine-template-type';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import EngineTemplateService from '@/service/engine-template.service';
import TempalteSyncPreviewDialog from './template-sync-preview-dialog.vue';

const Serveice = new EngineTemplateService();
const getFileName = (filePath: string) => {
    const fileName = filePath.match(/\/([^/]+)$/);
    if (fileName) {
        return fileName[1]; // 输出文件名称
    } else {
        return ''; // 输出文件名称
    }
};
@Component({
    components: {
        'tempalte-sync-preview-dialog': TempalteSyncPreviewDialog
    }
})
export default class WorkOrderFlowListComponent extends BaseComponent {
    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<any>;

    templateId: string = undefined;
    title: string = '导入模板';
    selectItem: string = 'basic';
    tableColumns: Array<any> = [
        {
            title: '占位符',
            dataIndex: 'elementSource',
            key: 'elementSource',
            scopedSlots: { customRender: 'elementSource' },
            width: 200
        },
        {
            title: '关联数据',
            dataIndex: 'dataJsonPath',
            key: 'dataJsonPath',
            scopedSlots: { customRender: 'dataJsonPath' }
        }
    ];
    corn: string = null;
    dateformat: Array<any> = [...Dateformat];
    buildReportTime: string = 'YYYY-MM-DD';
    contentStartTime: string = '';
    contentEndTime: string = '';
    reportFormat: string = '';
    docType: Array<any> = [{ label: 'PDF', value: 'PDF' }];
    resourceList: Array<any> = [];
    timeItem: string = '';
    timeOptions: Array<any> =[{ label: '不限', value: '' }, ...TimeOptions];
    datasetName: string = null;
    groupId: string = null;
    groupTreeData: Array<any> = [];
    treeData: Array<any> = [];
    orgTreeData: Array<any> = [];
    personList: Array<any> = [];
    form: any = {
        isUser: false,
        isOfficer: false,
        isDepartment: false,
        isAll: false,
        department: [],
        officer: [],
        user: []
    };
    messageChannel: Array<any> = [];
    prefixSuffix: string = null;
    reportName: string = null;
    channelList: Array<any> = ChannelList;
    @Watch('selectItem', { immediate: false })
    titleChange(newVal: string): void {
        switch (true) {
            case newVal === 'dataSet':
                this.title = '数据配置';
                break;
            case newVal === 'advance':
                this.title = '高级设置';
                break;
            default:
                this.title = '导入模板';
                break;
        }
    }
    created() {
        this.init();
    }
    // 切换步骤
    async switchStep(currentStep: string, type: string) {
        switch (true) {
            case currentStep === 'basic':
                if (!await this.jtlForm.validate()) {
                    this.showMessageError('请检查您提交的数据');
                    return;
                }
                this.selectItem = 'dataSet';
                // 刷新站位消息
                EngineTemplateService.getTemplateElementsInfo(this.templateId).then((res) => {
                    this.resourceList = res;
                });
                break;
            case currentStep === 'dataSet':
                if (type === 'prev') {
                    this.selectItem = 'basic';
                } else {
                    this.selectItem = 'advance';
                    const { templateUrl } = this.jtlForm.formModel;
                    this.reportFormat = FilterFileType(_.get(templateUrl, '[0].url'));
                    this.docType.unshift({ label: this.reportFormat, value: this.reportFormat });
                    EngineTemplateService.getReportTemplateScheduleSetting(this.templateId).then(res => {
                        console.log(res);
                        switch (res.receiveIdType) {
                            case 'ALL':
                                this.form.isAll = true;
                                break;
                            case 'GROUP':
                                this.form.isOfficer = true;
                                this.form.department = res.receiveId || [];
                                break;
                            case 'GROUP_MANGER':
                                this.form.isDepartment = true;
                                this.form.officer = res.receiveId || [];
                                break;
                            case 'SINGLE':
                                this.form.isUser = true;
                                this.form.user = res.receiveId || [];
                                break;
                            default:
                                break;
                        }
                        this.corn = res.corn || '';
                        this.messageChannel = res.messageChannel || [];
                        this.reportFormat = res.reportFormat || this.reportFormat || 'PDF';
                        this.reportName = res.reportName || '';
                        this.prefixSuffix = res.reportNamePrefix ? 'prefix' : (res.reportNameSuffix ? 'suffix' : '');
                        this.buildReportTime = res.reportNamePrefix ? res.reportNamePrefix : (res.reportNameSuffix ? res.reportNameSuffix : '');
                    });
                }
                break;
            case currentStep === 'advance':
                this.selectItem = 'dataSet';
                break;
            default:
                this.selectItem = 'basic';
                break;
        }
    }
    onLoadData(treeNode) {
        const { id } = treeNode.dataRef;
        EngineTemplateService.getDatasetField(id).then(res => {
            const result = _.map(res, item => {
                return {
                    title: item.originName,
                    key: `${item.id}`,
                    isLeaf: true,
                    ...item
                };
            });
            treeNode.dataRef.children = [...result];
        });
    }
    onGroupChange() {
        EngineTemplateService.getDatasetList({
            groupId: this?.groupId || '',
            datasetName: this?.datasetName || ''
        }).then(res => {
            this.treeData = res;
        });
    }
    // 预览
    priview() {
        this.startFullScreenLoading('正在生成预览');
        EngineTemplateService.previewReportGenerate({
            templateId: this.templateId,
            reportFileType: ''
        }).then(res => {
            (this.$refs.tempalteSyncPreviewDialog as TempalteSyncPreviewDialog).dialogOpen(res.url);
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
    async init() {
        this.templateId = this.$route.params.id !== 'add' ? this.$route.params.id : null;
        const id = this.templateId;
        let result: any = {};
        EngineTemplateService.getDatasetGroupList().then(res => {
            this.groupTreeData = res;
        });
        OrgService.getSelectOrgTree({ noProject: false }).then(res => {
            this.orgTreeData = res;
        });
        CommonService.getPersonList().then(res => {
            this.personList = _.map(res, item => {
                return {
                    value: item.value,
                    label: item.name
                };
            });
        });
        this.onGroupChange();
        if (this.templateId) {
            const res = await Serveice.retrieve(id);
            this.resourceList = res.resourceList;
            result = res;
            result.templateUrl = [{
                url: res.templateUrl,
                name: getFileName(res.templateUrl),
                status: 'done',
                type: 'file',
                uid: generateUUID()
            }];
        }
        this.$nextTick(() => {
            if (this.jtlForm) {
                const formControls = [
                    {
                        key: 'templateName',
                        label: '模板名称',
                        type: FormControlType.TEXT,
                        required: true,
                        value: ''
                    },
                    // {
                    //     key: 'templateType',
                    //     label: '模板类型',
                    //     type: FormControlType.SELECT,
                    //     options: _.map(TemplateType, item => ({ name: item.label, value: item.value })),
                    //     required: true
                    // },
                    {
                        key: 'description',
                        label: '说明',
                        type: FormControlType.TEXT_AREA,
                        placeholder: '最多输入200字',
                        value: ''
                    },
                    {
                        key: 'templateUrl',
                        label: '上传模板',
                        type: FormControlType.UPLOAD,
                        accept: '.doc,.docx,.ppt,.pptx,.xls,.xlsx',
                        maxFileNumber: 1,
                        showUploadList: true,
                        notUploadOss: true,
                        uploadUrl: '/infra-common/api/filestorage/upload',
                        // uploadUrl: '/infra-reportengine/api/report/file/upload',
                        message: '支持上传Word、PPT、Excel',
                        required: true
                    }
                ];
                this.jtlForm.initFormByControls(formControls, ViewModeType.NEW);
                this.jtlForm.formModel = result;
            }
        });
    }
    initParams() {
        const { description, templateName, templateUrl } = this.jtlForm.formModel;
        const data: any = {
            templateName,
            description,
            resourceList: this.resourceList,
            templateUrl: _.get(templateUrl, '[0].url')
        };
        if (this.templateId) {
            data.id = this.templateId;
        }
        if (this.selectItem === 'advance') {
            let receiveIdType = '';
            let receiveId = [];
            let reportNamePrefix = '';
            let reportNameSuffix = '';
            switch (true) {
                case !!this.form.isAll:
                    receiveIdType = 'ALL';
                    receiveId = [];
                    break;
                case !!this.form.isOfficer:
                    receiveIdType = 'GROUP';
                    receiveId = this.form.department;
                    break;
                case !!this.form.isDepartment:
                    receiveIdType = 'GROUP_MANGER';
                    receiveId = this.form.officer;
                    break;
                case !!this.form.isUser:
                    receiveIdType = 'SINGLE';
                    receiveId = this.form.user;
                    break;
                default:
                    receiveIdType = '';
                    receiveId = [];
                    break;
            }
            if (this.prefixSuffix === 'prefix') {
                reportNamePrefix = this.buildReportTime;
            } else if (this.prefixSuffix === 'suffix') {
                reportNameSuffix = this.buildReportTime;
            }
            return {
                reportNamePrefix,
                reportNameSuffix,
                receiveId,
                receiveIdType,
                reportName: this.reportName,
                messageChannel: this.messageChannel,
                reportFormat: this.reportFormat,
                templateId: this.templateId,
                corn: this.corn
            };
        }
        return data;
    }
    async saveFlow() {
        try {
            // 保存基本信息
            this.startFullScreenLoading('正在处理...');
            if (this.selectItem === 'basic') {
                if (!await this.jtlForm.validate()) {
                    this.stopFullScreenLoading();
                    this.showMessageError('请检查您提交的数据');
                } else {
                    if (this.templateId) {
                        await Serveice.update(this.initParams());
                    } else {
                        const itemId = await Serveice.created(this.initParams());
                        this.templateId = itemId;
                    }
                    this.showMessageSuccess('保存成功！');
                }
            } else if (this.selectItem === 'dataSet') {
                await Serveice.update(this.initParams());
            } else {
                if (!this.corn) {
                    this.showMessageError('请检查您提交的数据');
                    this.stopFullScreenLoading();
                    return;
                }
                await EngineTemplateService.saveReportTemplateScheduleSetting(this.initParams());
                this.cancel();
            }
            this.stopFullScreenLoading();
        } catch (error) {
            console.log(error);
            this.stopFullScreenLoading();
        }
    }
    formTypeChange(val: string, name: string): void {
        const value = this.form[name];
        this.form = {
            isUser: false,
            isOfficer: false,
            isDepartment: false,
            isAll: false,
            department: [],
            officer: [],
            user: []
        };
        this.form[name] = value;
    }
    onEnd(event) {
        console.log(event);
    }
    onTreeDragStart({ event, node }) {
        if (!node.dataRef.isLeaf) {
            // this.showMessage('分组不能分配到视图');
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        event.dataTransfer.setData('dragData', JSON.stringify(node.dataRef));
    }
    onDropTap(event, record, index) {
        const dragData = JSON.parse(event.dataTransfer.getData('dragData'));
        // console.log(event, record, index);
        debugger;
        this.resourceList.splice(index, 1, {
            ...record,
            dataJsonPath: `$[${0}].${dragData.fieldName}`,
            dataSourceId: dragData.datasetId,
            dataSourceType: dragData.fieldType
        });
        // this.resourceList.push({
        //     dataJsonPath: `$[${dragData.fieldIndex}].${dragData.fieldName}`,
        //     dataSourceId: dragData.datasetId,
        //     dataSourceType: dragData.fieldType,
        //     elementName: dragData.originName,
        //     elementSource: `{{${dragData.originName}}}`
        // });
    }
    deletetable(record, index) {
        this.resourceList.splice(index, 1);
    }
    editable(record, index, flag) {
        record.editable = flag;
        this.resourceList.splice(index, 1, record);
    }
    handleChange() {}
    cancel() {
        this.$router.push(`/dashboard/template-manager-list`);
    }
}
