var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-flow-design-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/template-manager-list" } },
                      [_vm._v("模板管理")]
                    ),
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.title))]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "a-radio-group",
            {
              attrs: { "default-value": "basic", "button-style": "solid" },
              model: {
                value: _vm.selectItem,
                callback: function ($$v) {
                  _vm.selectItem = $$v
                },
                expression: "selectItem",
              },
            },
            [
              _c(
                "a-radio-button",
                {
                  attrs: {
                    value: "basic",
                    disabled: _vm.selectItem !== "basic",
                  },
                },
                [_vm._v("基本信息")]
              ),
              _c(
                "a-radio-button",
                {
                  attrs: {
                    value: "dataSet",
                    disabled: _vm.selectItem !== "dataSet",
                  },
                },
                [_vm._v("数据配置")]
              ),
              _c(
                "a-radio-button",
                {
                  attrs: {
                    value: "advance",
                    disabled: _vm.selectItem !== "advance",
                  },
                },
                [_vm._v("高级设置")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "header-step-button" },
            [
              ["dataSet", "advance"].includes(_vm.selectItem)
                ? _c(
                    "a-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.switchStep(_vm.selectItem, "prev")
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
              ["basic", "dataSet"].includes(_vm.selectItem)
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.switchStep(_vm.selectItem, "next")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "page-body page-body-margin" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectItem === "basic",
                  expression: "selectItem === 'basic'",
                },
              ],
              staticClass: "content-basic",
            },
            [_c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME })],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectItem === "dataSet",
                  expression: "selectItem === 'dataSet'",
                },
              ],
              staticClass: "content-dataset",
            },
            [
              _c("div", { staticClass: "content-dataset-left" }, [
                _c("div", { staticClass: "dataset-left-filter" }, [
                  _c(
                    "div",
                    { staticClass: "left-filter-header" },
                    [
                      _c("span", [_vm._v("查询条件配置")]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.priview },
                        },
                        [_vm._v("预览")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "left-filter-body" },
                    [
                      _vm._v(" 时间： "),
                      _c("a-select", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          options: _vm.timeOptions,
                          "allow-clear": "",
                          placeholder: "请输入时间",
                        },
                        model: {
                          value: _vm.timeItem,
                          callback: function ($$v) {
                            _vm.timeItem = $$v
                          },
                          expression: "timeItem",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "dataset-left-table" },
                  [
                    _c("a-table", {
                      attrs: {
                        size: "small",
                        columns: _vm.tableColumns,
                        "data-source": _vm.resourceList,
                        pagination: false,
                        scroll: { y: 450 },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "dataJsonPath",
                          fn: function (text, record, index) {
                            return [
                              _c(
                                "div",
                                {
                                  on: {
                                    dragover: function ($event) {
                                      $event.preventDefault()
                                    },
                                    drop: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onDropTap(
                                        $event,
                                        record,
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("a-input", {
                                    staticStyle: { width: "500px" },
                                    attrs: { placeholder: "输入关联数据" },
                                    model: {
                                      value: record.dataJsonPath,
                                      callback: function ($$v) {
                                        _vm.$set(record, "dataJsonPath", $$v)
                                      },
                                      expression: "record.dataJsonPath",
                                    },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "jtl-del-link",
                                      attrs: { title: "删除" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deletetable(record, index)
                                        },
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "close" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-dataset-right" },
                [
                  _c(
                    "a-tabs",
                    { staticClass: "content-dataset-right-tab" },
                    [
                      _c(
                        "a-tab-pane",
                        {
                          key: "1",
                          staticClass: "content-dataset-right-tabItem",
                          attrs: { tab: "数据集" },
                        },
                        [
                          _c("a-tree-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "tree-data": _vm.groupTreeData,
                              "dropdown-style": {
                                maxHeight: "400px",
                                overflow: "auto",
                              },
                              "replace-fields": {
                                children: "children",
                                title: "groupName",
                                key: "id",
                                value: "id",
                              },
                              placeholder: "分组查询",
                              "allow-clear": "",
                              "tree-default-expand-all": "",
                            },
                            on: { change: _vm.onGroupChange },
                            model: {
                              value: _vm.groupId,
                              callback: function ($$v) {
                                _vm.groupId = $$v
                              },
                              expression: "groupId",
                            },
                          }),
                          _c("a-tree", {
                            attrs: {
                              draggable: "",
                              "load-data": _vm.onLoadData,
                              "tree-data": _vm.treeData,
                            },
                            on: { dragstart: _vm.onTreeDragStart },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function (ref) {
                                  var key = ref.key
                                  var title = ref.title
                                  var isLeaf = ref.isLeaf
                                  var fieldName = ref.fieldName
                                  return [
                                    _c(
                                      "div",
                                      [
                                        isLeaf
                                          ? _c("a-icon", {
                                              staticStyle: {
                                                "margin-right": "8px",
                                              },
                                              attrs: { type: "snippets" },
                                            })
                                          : _c("a-icon", {
                                              staticStyle: {
                                                "margin-right": "8px",
                                              },
                                              attrs: { type: "hdd" },
                                            }),
                                        _c("span", [_vm._v(_vm._s(title))]),
                                        isLeaf
                                          ? _c("span", [
                                              _vm._v(
                                                "               " +
                                                  _vm._s(fieldName)
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "tabBarExtraContent" },
                        [
                          _c("a-input-search", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              "allow-clear": "",
                              placeholder: "请输入名称查询",
                            },
                            on: { search: _vm.onGroupChange },
                            model: {
                              value: _vm.datasetName,
                              callback: function ($$v) {
                                _vm.datasetName = $$v
                              },
                              expression: "datasetName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectItem === "advance",
                  expression: "selectItem === 'advance'",
                },
              ],
              staticClass: "content-advance",
            },
            [
              _c("ul", { staticClass: "content-advance-box" }, [
                _c("li", { staticClass: "content-advance-li" }, [
                  _c("div", { staticClass: "advance-li-header" }, [
                    _vm._v("1. 报告生成设置"),
                  ]),
                  _c("ul", { staticClass: "advance-li-setting" }, [
                    _c(
                      "li",
                      [
                        _vm._v(" 生成报告名称："),
                        _c("a-input", {
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "输入生成报告名称" },
                          model: {
                            value: _vm.reportName,
                            callback: function ($$v) {
                              _vm.reportName = $$v
                            },
                            expression: "reportName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticStyle: { "padding-left": "100px" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            model: {
                              value: _vm.prefixSuffix,
                              callback: function ($$v) {
                                _vm.prefixSuffix = $$v
                              },
                              expression: "prefixSuffix",
                            },
                          },
                          [
                            _c("a-radio", { attrs: { value: "prefix" } }, [
                              _vm._v("增加前缀"),
                            ]),
                            _c("a-radio", { attrs: { value: "suffix" } }, [
                              _vm._v("增加后缀"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.prefixSuffix
                      ? _c("li", [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                "padding-left": "125px",
                              },
                            },
                            [
                              _c("span", [_vm._v("报告生成时间，格式定义 ")]),
                              _c("a-select", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  options: _vm.dateformat,
                                  placeholder: "请选择报告生成时间，格式定义",
                                },
                                model: {
                                  value: _vm.buildReportTime,
                                  callback: function ($$v) {
                                    _vm.buildReportTime = $$v
                                  },
                                  expression: "buildReportTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "li",
                      [
                        _vm._v(" 报告生成格式："),
                        _c("a-select", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            options: _vm.docType,
                            placeholder: "请输入报告生成格式",
                          },
                          model: {
                            value: _vm.reportFormat,
                            callback: function ($$v) {
                              _vm.reportFormat = $$v
                            },
                            expression: "reportFormat",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("li", { staticClass: "content-advance-li" }, [
                  _c("div", { staticClass: "advance-li-header" }, [
                    _vm._v("报告生成周期设置"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "advance-li-setting",
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _vm._v(" 报告生成周期规则:   "),
                      _c("jtl-cron-component", {
                        staticClass: "cron-value",
                        model: {
                          value: _vm.corn,
                          callback: function ($$v) {
                            _vm.corn = $$v
                          },
                          expression: "corn",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("li", { staticClass: "content-advance-li" }, [
                  _c("div", { staticClass: "advance-li-header" }, [
                    _vm._v("报告发送对象"),
                  ]),
                  _c("ul", { staticClass: "advance-li-setting" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "a-checkbox",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            on: {
                              change: function ($event) {
                                return _vm.formTypeChange("1", "isAll")
                              },
                            },
                            model: {
                              value: _vm.form.isAll,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isAll", $$v)
                              },
                              expression: "form.isAll",
                            },
                          },
                          [_vm._v("所有人")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "a-checkbox",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            on: {
                              change: function ($event) {
                                return _vm.formTypeChange("2", "isOfficer")
                              },
                            },
                            model: {
                              value: _vm.form.isOfficer,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isOfficer", $$v)
                              },
                              expression: "form.isOfficer",
                            },
                          },
                          [_vm._v("指定部门")]
                        ),
                        _c("br"),
                        _vm.form.isOfficer
                          ? _c("jtl-tree-select-component", {
                              staticStyle: {
                                width: "450px",
                                "margin-left": "20px",
                              },
                              attrs: {
                                multiple: true,
                                "tree-data": _vm.orgTreeData,
                                placeholder: "请选择指定部门",
                              },
                              model: {
                                value: _vm.form.department,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "department", $$v)
                                },
                                expression: "form.department",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "a-checkbox",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            on: {
                              change: function ($event) {
                                return _vm.formTypeChange("3", "isDepartment")
                              },
                            },
                            model: {
                              value: _vm.form.isDepartment,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isDepartment", $$v)
                              },
                              expression: "form.isDepartment",
                            },
                          },
                          [_vm._v("指定部门负责人")]
                        ),
                        _c("br"),
                        _vm.form.isDepartment
                          ? _c("a-select", {
                              staticStyle: {
                                width: "450px",
                                "margin-bottom": "10px",
                                "margin-left": "20px",
                              },
                              attrs: {
                                options: _vm.personList,
                                mode: "multiple",
                                placeholder: "请选择指定部门负责人",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.form.officer,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "officer", $$v)
                                },
                                expression: "form.officer",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "a-checkbox",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            on: {
                              change: function ($event) {
                                return _vm.formTypeChange("4", "isUser")
                              },
                            },
                            model: {
                              value: _vm.form.isUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isUser", $$v)
                              },
                              expression: "form.isUser",
                            },
                          },
                          [_vm._v("指定人")]
                        ),
                        _c("br"),
                        _vm.form.isUser
                          ? _c("a-select", {
                              staticStyle: {
                                width: "450px",
                                "margin-bottom": "10px",
                                "margin-left": "20px",
                              },
                              attrs: {
                                options: _vm.personList,
                                mode: "multiple",
                                placeholder: "请选择指定人",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.form.user,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "user", $$v)
                                },
                                expression: "form.user",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("li", { staticClass: "content-advance-li" }, [
                  _c("div", { staticClass: "advance-li-header" }, [
                    _vm._v("消息通知配置"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "advance-li-channel-content" },
                    [
                      _c(
                        "div",
                        { staticClass: "advance-li-channel-content-header" },
                        [_vm._v("消息发送渠道：")]
                      ),
                      _c(
                        "a-checkbox-group",
                        {
                          staticClass: "advance-li-channel-content-checkbox",
                          model: {
                            value: _vm.messageChannel,
                            callback: function ($$v) {
                              _vm.messageChannel = $$v
                            },
                            expression: "messageChannel",
                          },
                        },
                        _vm._l(_vm.channelList, function (item) {
                          return _c(
                            "a-row",
                            {
                              key: item.value,
                              staticClass: "channel-content-checkbox-row",
                            },
                            [
                              _c(
                                "a-col",
                                {
                                  staticClass: "channel-checkbox-col",
                                  attrs: { span: 24 },
                                },
                                [
                                  _c(
                                    "a-checkbox",
                                    { attrs: { value: item.value } },
                                    [_vm._v(_vm._s(item.label))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _vm.selectItem === "basic"
              ? _c(
                  "jtl-button",
                  {
                    staticClass: "margin-right-18",
                    attrs: { type: "cancel" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("取消")]
                )
              : _vm._e(),
            _c(
              "jtl-button",
              {
                staticClass: "margin-right-18",
                attrs: { type: "primary" },
                on: { click: _vm.saveFlow },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
      _c("tempalte-sync-preview-dialog", { ref: "tempalteSyncPreviewDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }